import { Link } from "gatsby"
import React from "react"

const Header = () => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1300,
        padding: `1.45rem 1.0875rem`,
      }}
      className="nav"
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          automotive<br/>universal collection
        </Link>
      </h1>
      <div className="contact"><a href="mailto:giampiero.viola1929@gmail.com">Contatto</a></div>
    </div>
  </header>
)



export default Header
